* {
  font-family: "Commissioner", sans-serif;
}

/* in contact */
.contactlabel {
  color: white;
  padding: 0.2rem;
  font-weight: 550;
}
.fbhover:hover {
  color: #adadad !important;
}

/* in footer */
.facebooklink {
  color: white;
  text-decoration: none;
  margin-bottom: 1rem;
}
.facebooklink:hover {
  color: #adadad;
}
.ruby {
  color: #adadad;
  text-decoration: none !important;
}
.ruby:hover {
  color: #ff0000;
  text-decoration: none !important;
}

/* when navbar is collapsed */
@media only screen and (max-width: 767px) {
  /* in navbar */
  .navbar-nav .nav-link {
    padding: 1rem !important;
    display: flex;
    justify-content: center;
    background-color: none;
  }
  .navbar-nav .nav-link:hover,
  .navbar-nav .nav-link:active {
    background-color: #ffffff;
    color: #000000 !important;
  }

  .navbar-collapse.collapsing,
  .navbar-collapse.show {
    padding: 0rem;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  /* in footer */
  .footer {
    background-color: #000000;
    width: 100%;
    bottom: 0;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px,
      rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
      rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
  }
  .footerbody {
    padding: 1rem;
    min-height: 15rem;
    justify-content: space-between;
  }
  .footerbio {
    padding-top: 1rem;
    padding-right: 1rem;
    padding-left: 1rem;
    padding-bottom: 0.5rem;
    color: white;
    display: flex;
    flex-direction: column;
    border-bottom: 3px solid white;
  }
  .footernavdiv {
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-bottom: 3px solid white;
  }
  .footerinfo {
    color: white;
    padding-top: 1rem;
    padding-right: 1rem;
    padding-left: 1rem;
    padding-bottom: 0.5rem;
  }
  .footerimg {
    height: 1.3rem;
  }
  .footercopyright {
    width: 100%;
    background-color: #1c1c1c;
    color: white;
    display: flex;
    justify-content: center;
    align-content: center;
  }
  .footernavtitle {
    font-size:1rem;
    color: white;
    margin: 0;
    margin-right: 0.9rem;
    margin-left: 1rem;
  }
  .footernav {
    display: table;
    table-layout: fixed;
    margin-top: 0.5rem;
  }
  .footerlink {
    font-size: .7rem;
    margin: 0rem;
    color: white !important;
  }
  .footerlink:hover {
    color: #adadad !important;
  }
  .footerp{
    font-size:.7rem;
  }
  .footerhead{
    margin-bottom:1rem;
    font-weight:650;
    font-size:1rem;
  }
  .footertouch{
    font-size:.7rem;
  }

  /* in about */
  .bio {
    background-color: #ff0000;
    padding: 2rem;
    color: white;
    margin-bottom: 4rem;
  }
  .biotext {
    margin: 0;
    text-align: center;
    font-size: 1rem;
  }
  .chrispaul {
    margin: 0;
    text-align: center;
    font-size: .8rem;
  }
  .fact1 {
    background-color: #424242;
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 1rem;
    margin-bottom: 1.5rem;
    border-radius: 1rem;
    height: 22vw;
    width: 22vw;
    font-size: 5.7vw;
    font-weight: 800;
  }
  .fact2 {
    background-color: #424242;
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 1rem;
    margin-bottom: 1.5rem;
    border-radius: 1rem;
    height: 22vw;
    width: 22vw;
    font-size: 2.3vw;
    font-weight: 800;
  }
  .fact3 {
    background-color: #424242;
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 1rem;
    margin-bottom: 1.5rem;
    border-radius: 1rem;
    height: 22vw;
    width: 22vw;
    font-size: 3.7vw;
    font-weight: 800;
  }
  .fact4 {
    background-color: #424242;
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 1rem;
    margin-bottom: 1.5rem;
    border-radius: 1rem;
    height: 22vw;
    width: 22vw;
    font-size: 2.3vw;
    font-weight: 800;
  }
  .facts {
    display: flex;
    color: white;
    justify-content: space-evenly;
  }
  .title {
    font-size: 8vw;
    color: white;
    display: flex;
    justify-content: center;
    margin-top: 3rem;
    font-weight: 900;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2),
      0px -5px 35px rgba(255, 255, 255, 0.3);
  }
  .aboutsection {
    margin-top: 3rem;
  }
  .personbiodiv {
    color: white;
    background-color: #424242;
    padding: 2rem;
    text-align: center;
  }
  .personimg {
    height: 30vw;
    display: none;
  }
  .personimgsmall {
    padding-bottom: 1rem;
    padding-top: 1rem;
  }
  .abouttitle {
    font-weight: 900;
    margin-bottom: 0.5rem;
  }
  .atitle {
    font-size: 8vw;
    color: white;
    display: flex;
    justify-content: center;
    margin-top: 3rem;
    font-weight: 900;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2),
      0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  /* in services */
  .servicesdiv {
    background-color: #454545;
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding: 2rem;
  }
  .service {
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1.5rem;
    text-align: center;
  }
  .serviceicon {
    width: 40vw;
    margin-bottom: 2rem;
    border-radius: 0.2rem;
  }
  .serviceicon2 {
    width: 80vw;
    margin-bottom: 2rem;
    border-radius: 0.2rem;
  }
  .serviceborder {
    border-bottom: 3px solid white;
  }
  .servicehead {
    font-size: 4.5vw;
    font-weight: 800;
  }
  .servicep {
    font-size: 3vw;
  }
  .servicetitle {
    font-size: 8vw;
    color: white;
    display: flex;
    justify-content: center;
    margin-top: 3rem;
    font-weight: 900;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2),
      0px -5px 35px rgba(255, 255, 255, 0.3);
  }
  .actionpics {
    display: table;
    table-layout: fixed;
    /* width: 100%; */
    text-align: center;
  }
  .actionpic {
    height: 11.1rem;
    border-radius: 0.6rem;
    padding: 0.8rem;
  }
  /* .actionpic:hover {
    height: 16rem;
    border-radius: 0.6rem;
    padding: 0.8rem;
  } */
  .actionpic2 {
    height: 9.5rem;
    border-radius: 0.6rem;
    padding: 0.8rem;
  }
  /* .actionpic2:hover {
    height: 13.5rem;
    border-radius: 0.6rem;
    padding: 0.8rem;
  } */
  .infodiv {
    background-color: #ff0000;
    color: white;
    padding: 1rem;
    width: fit-content;
    display: flex;
    justify-content: center;
    margin: auto;
    border-radius: 0.3rem;
    margin-bottom: 2rem;
  }
  .moreinfo {
    margin: 0;
    font-weight: 650;
    font-size: 3vw;
    text-align: center;
  }

  /* in home */
  .homehead {
    font-size: 2rem;
    font-weight: 900;
    text-shadow: 5px 5px 0px rgba(0, 0, 0, 0.2);
  }
  .hometitle {
    font-size: 1rem;
    font-weight: 800;
    text-shadow: 5px 5px 0px rgba(0, 0, 0, 0.2);
  }
  .homeimg {
    object-fit: cover;
    width: 100%;
  }
  .headerdiv {
    position: absolute;
    top: 55%;
    left: 32%;
    transform: translate(-50%, -50%);
  }
  .homephone {
    font-size: 1.2rem;
    margin: 0;
    padding: 0.5rem;
    background-color: #000000;
  }
  .homeicon {
    height: 1rem;
  }
  .logo{
    height:10vw;
  }
  .protecthead {
    font-size: 4.5vw;
    font-weight: 800;
  }
  .protectp {
    font-size: 3vw;
  }
  .protect {
    background-color: #424242;
    padding-top: 2rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 1rem;
    margin-top: 3rem;
    margin-bottom: 4rem;
    color: white;
    display: flex;
  }
  .protecttext {
    width: 60%;
  }
  .protectlist {
    padding-top: 0.5rem;
    width: 40%;
    font-size: 3vw;
    font-weight: 650;
  }

  /* in contact */
  .contactdiv {
    background-color: #424242;
    border-radius: 0.5rem;
    padding-right: 1rem;
    padding-left: 1rem;
    color: white;
    width: fit-content;
    margin: auto;
  }
  .connect {
    display: flex !important;
    align-items: center;
    justify-content: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: .5rem;
    padding-left: .5rem;
  }
  .connectimg {
    height: 2rem;
    padding: .3rem;
  }
  .connectborder {
    border-bottom: 3px solid white;
  }
  .connecttext {
    font-size: 3.2vw;
    margin: 0;
    font-weight: 650;
  }
  .message {
    display: flex;
    justify-content: center;
    color: white;
    font-size: 5vw;
    font-weight: 650;
  }
  .contactlabel{
    font-size:3.2vw;
  }
  .contactcontrol{
    font-size:3.2vw !important;
  }
  .contactbutton{
    font-size: 3.2vw !important;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  /* in footer */
  .footer {
    background-color: #000000;
    width: 100%;
    bottom: 0;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px,
      rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
      rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
  }
  .footerbody {
    padding: 1.3rem;
    min-height: 15rem;
    justify-content: space-between;
  }
  .footerbio {
    padding-top: 1rem;
    padding-right: 1rem;
    padding-left: 1rem;
    padding-bottom: 0.5rem;
    color: white;
    display: flex;
    flex-direction: column;
    border-bottom: 3px solid white;
  }
  .footernavdiv {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-bottom: 3px solid white;
  }
  .footerinfo {
    color: white;
    padding-top: 1rem;
    padding-right: 1rem;
    padding-left: 1rem;
    padding-bottom: 0.5rem;
  }
  .footerimg {
    height: 1.3rem;
  }
  .footercopyright {
    width: 100%;
    background-color: #1c1c1c;
    color: white;
    display: flex;
    justify-content: center;
    align-content: center;
  }
  .footernavtitle {
    color: white;
    margin: 0;
    margin-right: 1rem;
  }
  .footerlink {
    font-size: 1rem;
    margin: 0rem;
    color: white !important;
  }
  .footerlink:hover {
    color: #adadad !important;
  }
  .footerp{
    font-size:1rem;
  }
  .footerhead{
    margin-bottom:1.5rem;
    font-weight:650;
  }

  /* in about */
  .bio {
    background-color: #ff0000;
    padding: 2rem;
    color: white;
    margin-bottom: 4rem;
  }
  .biotext {
    margin: 0;
    text-align: center;
    font-size: 1rem;
  }
  .chrispaul {
    margin: 0;
    text-align: center;
    font-size: 1rem;
  }
  .title {
    font-size: 8vw;
    color: white;
    display: flex;
    justify-content: center;
    margin-top: 3rem;
    font-weight: 900;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2),
      0px -5px 35px rgba(255, 255, 255, 0.3);
  }
  .fact1 {
    background-color: #424242;
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 1rem;
    margin-bottom: 1.5rem;
    border-radius: 1rem;
    height: 20vw;
    width: 20vw;
    font-size: 5.7vw;
    font-weight: 800;
  }
  .fact2 {
    background-color: #424242;
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 1rem;
    margin-bottom: 1.5rem;
    border-radius: 1rem;
    height: 20vw;
    width: 20vw;
    font-size: 2.2vw;
    font-weight: 800;
  }
  .fact3 {
    background-color: #424242;
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 1rem;
    margin-bottom: 1.5rem;
    border-radius: 1rem;
    height: 20vw;
    width: 20vw;
    font-size: 3.8vw;
    font-weight: 800;
  }
  .fact4 {
    background-color: #424242;
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 1rem;
    margin-bottom: 1.5rem;
    border-radius: 1rem;
    height: 20vw;
    width: 20vw;
    font-size: 2.3vw;
    font-weight: 800;
  }
  .facts {
    display: flex;
    color: white;
    justify-content: space-evenly;
  }
  .aboutsection {
    display: flex;
    margin-top: 3rem;
  }
  .personbiodiv {
    color: white;
    background-color: #424242;
    padding: 2rem;
    text-align: center;
    min-width: 36rem;
  }
  .personimg {
    object-fit: cover;
  }
  .personimgsmall {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .abouttitle {
    font-weight: 900;
    margin-bottom: 2rem;
  }
  .atitle {
    font-size: 8vw;
    color: white;
    display: flex;
    justify-content: center;
    margin-top: 3rem;
    font-weight: 900;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2),
      0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  /* in services */
  .servicesdiv {
    background-color: #454545;
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding: 2rem;
  }
  .service {
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1.5rem;
    text-align: center;
  }
  .serviceicon {
    width: 40vw;
    margin-bottom: 2rem;
    border-radius: 0.2rem;
  }
  .serviceicon2 {
    width: 80vw;
    margin-bottom: 2rem;
    border-radius: 0.2rem;
  }
  .serviceborder {
    border-bottom: 3px solid white;
  }
  .servicehead {
    font-size: 4.5vw;
    font-weight: 800;
  }
  .servicep {
    font-size: 3vw;
  }
  .servicetitle {
    font-size: 8vw;
    color: white;
    display: flex;
    justify-content: center;
    margin-top: 3rem;
    font-weight: 900;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2),
      0px -5px 35px rgba(255, 255, 255, 0.3);
  }
  .actionpics {
    display: table;
    table-layout: fixed;
    width: 100%;
    text-align: center;
  }
  .actionpic {
    height: 15rem;
    border-radius: 0.6rem;
    padding: 0.8rem;
  }
  /* .actionpic:hover {
    height: 16rem;
    border-radius: 0.6rem;
    padding: 0.8rem;
  } */
  .actionpic2 {
    height: 12.5rem;
    border-radius: 0.6rem;
    padding: 0.8rem;
  }
  /* .actionpic2:hover {
    height: 13.5rem;
    border-radius: 0.6rem;
    padding: 0.8rem;
  } */
  .infodiv {
    background-color: #ff0000;
    color: white;
    padding: 1rem;
    width: fit-content;
    display: flex;
    justify-content: center;
    margin: auto;
    border-radius: 0.3rem;
    margin-bottom: 2rem;
  }
  .moreinfo {
    margin: 0;
    font-weight: 650;
    font-size: 2.4vw;
    text-align: center;
  }

  /* in home */
  .homehead {
    font-size: 3.2rem;
    font-weight: 900;
    text-shadow: 5px 5px 0px rgba(0, 0, 0, 0.2);
  }
  .hometitle {
    font-size: 1.4rem;
    font-weight: 800;
    text-shadow: 5px 5px 0px rgba(0, 0, 0, 0.2);
  }
  .homeimg {
    object-fit: cover;
    width: 100%;
  }
  .headerdiv {
    position: absolute;
    top: 53%;
    left: 35%;
    transform: translate(-50%, -50%);
  }
  .homephone {
    font-size: 1.4rem;
    margin: 0;
    padding: 0.5rem;
    background-color: #000000;
  }
  .homeicon {
    height: 1.2rem;
  }
  .logo{
    height:10vw;
  }
  .protecthead {
    font-size: 4.5vw;
    font-weight: 800;
  }
  .protectp {
    font-size: 3vw;
  }
  .protect {
    background-color: #424242;
    padding-top: 2rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 1rem;
    margin-top: 3rem;
    margin-bottom: 4rem;
    color: white;
    display: flex;
  }
  .protecttext {
    width: 60%;
  }
  .protectlist {
    padding-top: 0.5rem;
    width: 40%;
    font-size: 3vw;
    font-weight: 650;
  }

  /* in contact */
  .contactdiv {
    background-color: #424242;
    border-radius: 0.5rem;
    padding-right: 2rem;
    padding-left: 2rem;
    color: white;
    width: fit-content;
    margin: auto;
  }
  .connect {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
  }
  .connectimg {
    height: 4rem;
    padding: 1rem;
  }
  .connectborder {
    border-bottom: 3px solid white;
  }
  .connecttext {
    font-size: 2.5vw;
    margin: 0;
    font-weight: 650;
  }
  .message {
    display: flex;
    justify-content: center;
    color: white;
    font-size: 3vw;
    font-weight: 650;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  /* in footer */
  .footer {
    background-color: #000000;
    width: 100%;
    bottom: 0;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px,
      rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
      rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
  }
  .footerbody {
    padding: 1.3rem;
    min-height: 15rem;
    display: flex;
    justify-content: space-between;
  }
  .footerbio {
    padding-top: 1rem;
    padding-right: 1rem;
    padding-left: 1rem;
    padding-bottom: 0.5rem;
    color: white;
    width: 33.3%;
    display: flex;
    flex-direction: column;
    border-right: 3px solid white;
    border-bottom: none;
  }
  .footernavdiv {
    width: 33.3%;
    display: flex;
    flex-direction: column;
    border-right: 3px solid white;
    border-bottom: none;
    justify-content: flex-start;
  }
  .footernav {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: none;
  }
  .footerinfo {
    width: 33.3%;
    color: white;
    padding-top: 1rem;
    padding-right: 1rem;
    padding-left: 1.7rem;
    padding-bottom: 0.5rem;
  }
  .footerimg {
    height: 1.3rem;
  }
  .footercopyright {
    width: 100%;
    background-color: #1c1c1c;
    color: white;
    display: flex;
    justify-content: center;
    align-content: center;
  }
  .footernavtitle {
    color: white;
    margin-bottom: 1rem !important;
    /* margin-right: none; */
  }
  .footerlink {
    font-size: 1rem;
    margin: 0rem;
    color: white !important;
  }
  .footerlink :hover {
    color: #454545 !important;
  }
  .footerp{
    font-size:1rem;
  }
  .footerhead{
    margin-bottom:1.5rem;
    font-weight:650;
  }

  /* in about */
  .bio {
    background-color: #ff0000;
    padding: 2rem;
    color: white;
    margin-bottom: 4rem;
  }
  .biotext {
    margin: 0;
    text-align: center;
    font-size: 1.1rem;
  }
  .chrispaul {
    margin: 0;
    text-align: center;
    font-size: 1rem;
  }
  .title {
    font-size: 5vw;
    color: white;
    display: flex;
    justify-content: center;
    margin-top: 3rem;
    font-weight: 900;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2),
      0px -5px 35px rgba(255, 255, 255, 0.3);
  }
  .fact1 {
    background-color: #424242;
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 1rem;
    margin-bottom: 1.5rem;
    border-radius: 1rem;
    height: 18vw;
    width: 18vw;
    font-size: 5.7vw;
    font-weight: 800;
  }
  .fact2 {
    background-color: #424242;
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 1rem;
    margin-bottom: 1.5rem;
    border-radius: 1rem;
    height: 18vw;
    width: 18vw;
    font-size: 1.8vw;
    font-weight: 800;
  }
  .fact3 {
    background-color: #424242;
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 1rem;
    margin-bottom: 1.5rem;
    border-radius: 1rem;
    height: 18vw;
    width: 18vw;
    font-size: 3.7vw;
    font-weight: 800;
  }
  .fact4 {
    background-color: #424242;
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 1rem;
    margin-bottom: 1.5rem;
    border-radius: 1rem;
    height: 18vw;
    width: 18vw;
    font-size: 2.3vw;
    font-weight: 800;
  }
  .facts {
    display: flex;
    color: white;
    justify-content: space-evenly;
  }
  .servicetitle {
    font-size: 4vw;
    color: white;
    display: flex;
    justify-content: center;
    margin: 2rem;
    font-weight: 900;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2),
      0px -5px 35px rgba(255, 255, 255, 0.3);
  }
  .aboutsection {
    display: flex;
    margin-top: 3rem;
  }
  .personbiodiv {
    color: white;
    background-color: #424242;
    padding: 2rem;
    text-align: center;
    min-width: 36rem;
  }
  .personimg {
    object-fit: cover;
  }
  .personimgsmall {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .abouttitle {
    font-weight: 900;
    margin-bottom: 2rem;
  }
  .atitle {
    font-size: 4.5vw;
    color: white;
    display: flex;
    justify-content: center;
    margin-top: 3rem;
    font-weight: 900;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2),
      0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  /* in services */
  .servicesdiv {
    display: flex;
    background-color: #454545;
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding: 2rem;
  }
  .service {
    width: 33.3%;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1.5rem;
    text-align: center;
  }
  .serviceicon {
    width: 20vw;
    margin-bottom: 2rem;
    border-radius: 0.2rem;
  }
  .serviceicon2 {
    width: 25vw;
    margin-bottom: 2rem;
    border-radius: 0.2rem;
  }
  .servicehead {
    font-size: 3.2vw;
    font-weight: 800;
  }
  .servicep {
    font-size: 2vw;
  }
  .servicetitle {
    font-size: 4.5vw;
    color: white;
    display: flex;
    justify-content: center;
    margin-top: 3rem;
    font-weight: 900;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2),
      0px -5px 35px rgba(255, 255, 255, 0.3);
  }
  .actionpics {
    display: table;
    table-layout: fixed;
    width: 100%;
    text-align: center;
  }
  .actionpic {
    height: 15rem;
    border-radius: 0.6rem;
    padding: 0.8rem;
  }
  /* .actionpic:hover {
    height: 16rem;
    border-radius: 0.6rem;
    padding: 0.8rem;
  } */
  .actionpic2 {
    height: 15rem;
    border-radius: 0.6rem;
    padding: 0.8rem;
  }
  /* .actionpic2:hover {
    height: 16rem;
    border-radius: 0.6rem;
    padding: 0.8rem;
  } */
  .serviceborder {
    border-right: 3px solid white;
    border-bottom: none;
  }
  .infodiv {
    background-color: #ff0000;
    color: white;
    padding: 1rem;
    width: fit-content;
    display: flex;
    justify-content: center;
    margin: auto;
    border-radius: 0.3rem;
    margin-bottom: 2rem;
  }
  .moreinfo {
    margin: 0;
    font-weight: 650;
    font-size: 2.2vw;
    text-align: center;
  }

  /* in home */
  .homehead {
    font-size: 4rem;
    font-weight: 900;
    text-shadow: 5px 5px 0px rgba(0, 0, 0, 0.2);
  }
  .hometitle {
    font-size: 2rem;
    font-weight: 800;
    text-shadow: 5px 5px 0px rgba(0, 0, 0, 0.2);
  }
  .homeimg {
    object-fit: cover;
    width: 100%;
  }
  .headerdiv {
    position: absolute;
    top: 50%;
    left: 35%;
    transform: translate(-50%, -50%);
  }
  .homephone {
    font-size: 1.4rem;
    margin: 0;
    padding: 0.5rem;
    background-color: #000000;
  }
  .homeicon {
    height: 1.2rem;
  }
  .logo{
    height:10vw;
  }
  .protecthead {
    font-size: 3.2vw;
    font-weight: 800;
  }
  .protectp {
    font-size: 2vw;
  }
  .protect {
    background-color: #424242;
    padding-top: 2rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 1rem;
    margin-top: 3rem;
    margin-bottom: 4rem;
    color: white;
    display: flex;
  }
  .protecttext {
    width: 60%;
    padding-right: 10rem;
  }
  .protectlist {
    padding-top: 0.5rem;
    width: 40%;
    font-size: 2vw;
    font-weight: 650;
  }

  /* in contact */
  .contactdiv {
    background-color: #424242;
    border-radius: 0.5rem;
    padding-right: 2rem;
    padding-left: 2rem;
    color: white;
    width: fit-content;
    margin: auto;
  }
  .connect {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
  }
  .connectimg {
    height: 4rem;
    padding: 1rem;
  }
  .connectborder {
    border-bottom: 3px solid white;
  }
  .connecttext {
    font-size: 2vw;
    margin: 0;
    font-weight: 650;
  }
  .message {
    display: flex;
    justify-content: center;
    color: white;
    font-size: 2.6vw;
    font-weight: 650;
  }
}

@media only screen and (max-width: 991px) {
  .personimg {
    display: none;
  }
}
@media only screen and (max-width: 834px) {
  .actionpic1 {
    display: none;
  }
}
@media only screen and (min-width: 835px) {
  .actionpic1 {
    height: 15rem;
    border-radius: 0.6rem;
    padding: 0.8rem;
  }
  /* .actionpic1:hover {
    height: 16rem;
    border-radius: 0.6rem;
    padding: 0.8rem;
  } */
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  /* in footer */
  .footer {
    background-color: #000000;
    width: 100%;
    bottom: 0;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px,
      rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
      rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
  }
  .footerbody {
    min-height: 15rem;
    display: flex;
    justify-content: space-between;
  }
  .footerbio {
    padding-top: 2rem;
    padding-right: 2rem;
    padding-left: 2rem;
    color: white;
    width: 33.3%;
    display: flex;
    flex-direction: column;
    border-right: 3px solid white;
  }
  .footernavdiv {
    width: 33.3%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-right: 3px solid white;
    border-bottom: none;
    padding-top: 2rem;
    padding-right: 2rem;
    padding-left: 2rem;
    justify-content: flex-start;
  }
  .footernav {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: none;
  }
  .footerinfo {
    width: 33.3%;
    color: white;
    padding-top: 2rem;
    padding-right: 2rem;
    padding-left: 2rem;
    padding-bottom: 1rem;
  }
  .footerimg {
    height: 1.3rem;
  }
  .footercopyright {
    width: 100%;
    background-color: #1c1c1c;
    color: white;
    display: flex;
    justify-content: center;
    align-content: center;
  }
  .footernavtitle {
    color: white;
    margin: 0;
  }
  .footerlink {
    font-size: 1rem;
    margin: 0rem;
    color: white !important;
  }
  .footerlink :hover {
    color: #454545 !important;
  }
  .footerp{
    font-size:1rem;
  }
  .footerhead{
    margin-bottom:1.5rem;
    font-weight:650;
  }

  /* in about */
  .bio {
    background-color: #ff0000;
    padding: 2rem;
    color: white;
    margin-bottom: 4rem;
  }
  .biotext {
    margin: 0;
    text-align: center;
    font-size: 1.2rem;
  }
  .chrispaul {
    margin: 0;
    text-align: center;
    font-size: 1.1rem;
  }
  .title {
    font-size: 4vw;
    color: white;
    display: flex;
    justify-content: center;
    margin-top: 3rem;
    font-weight: 900;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2),
      0px -5px 35px rgba(255, 255, 255, 0.3);
  }
  .fact1 {
    background-color: #424242;
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 1rem;
    margin-bottom: 1.5rem;
    border-radius: 1rem;
    height: 18vw;
    width: 18vw;
    font-size: 5.7vw;
    font-weight: 800;
  }
  .fact2 {
    background-color: #424242;
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 1rem;
    margin-bottom: 1.5rem;
    border-radius: 1rem;
    height: 18vw;
    width: 18vw;
    font-size: 1.9vw;
    font-weight: 800;
  }
  .fact3 {
    background-color: #424242;
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 1rem;
    margin-bottom: 1.5rem;
    border-radius: 1rem;
    height: 18vw;
    width: 18vw;
    font-size: 3.7vw;
    font-weight: 800;
  }
  .fact4 {
    background-color: #424242;
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 1rem;
    margin-bottom: 1.5rem;
    border-radius: 1rem;
    height: 18vw;
    width: 18vw;
    font-size: 2.3vw;
    font-weight: 800;
  }
  .facts {
    display: flex;
    color: white;
    justify-content: space-evenly;
  }
  .atitle {
    font-size: 4vw;
    color: white;
    display: flex;
    justify-content: center;
    margin-top: 3rem;
    font-weight: 900;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2),
      0px -5px 35px rgba(255, 255, 255, 0.3);
  }
  .aboutsection {
    display: flex;
    height: 20rem;
    margin-top: 3rem;
  }
  .personbiodiv {
    color: white;
    background-color: #424242;
    padding: 3rem;
    text-align: center;
    min-width: 36rem;
  }
  .personimg {
    object-fit: cover;
  }
  .personimgsmall {
    display: none;
  }
  .abouttitle {
    font-weight: 900;
    margin-bottom: 2rem;
  }

  /* in services */
  .servicesdiv {
    display: flex;
    background-color: #454545;
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding: 2rem;
  }
  .service {
    width: 33.3%;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1.5rem;
    text-align: center;
  }
  .serviceicon {
    width: 20vw;
    margin-bottom: 2rem;
    border-radius: 0.2rem;
  }
  .serviceicon2 {
    width: 25vw;
    margin-bottom: 2rem;
    border-radius: 0.2rem;
  }
  .servicehead {
    font-size: 3.2vw;
    font-weight: 800;
  }
  .servicep {
    font-size: 2vw;
  }
  .servicetitle {
    font-size: 4vw;
    color: white;
    display: flex;
    justify-content: center;
    margin-top: 3rem;
    font-weight: 900;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2),
      0px -5px 35px rgba(255, 255, 255, 0.3);
  }
  .actionpics {
    display: table;
    table-layout: fixed;
    width: 100%;
    text-align: center;
  }
  .actionpic {
    height: 15rem;
    border-radius: 0.6rem;
    padding: 0.8rem;
  }
  /* .actionpic:hover {
    height: 16rem;
    border-radius: 0.6rem;
    padding: 0.8rem;
  } */
  .actionpic2 {
    height: 15rem;
    border-radius: 0.6rem;
    padding: 0.8rem;
  }
  /* .actionpic2:hover {
    height: 16rem;
    border-radius: 0.6rem;
    padding: 0.8rem;
  } */
  .serviceborder {
    border-right: 3px solid white;
  }
  .infodiv {
    background-color: #ff0000;
    color: white;
    padding: 1rem;
    width: fit-content;
    display: flex;
    justify-content: center;
    margin: auto;
    border-radius: 0.3rem;
    margin-bottom: 2rem;
  }
  .moreinfo {
    margin: 0;
    font-weight: 650;
    font-size: 1.6vw;
    text-align: center;
  }

  /* in home */
  .homehead {
    font-size: 5rem;
    font-weight: 900;
    text-shadow: 5px 5px 0px rgba(0, 0, 0, 0.2);
  }
  .hometitle {
    font-size: 2.5rem;
    font-weight: 800;
    text-shadow: 5px 5px 0px rgba(0, 0, 0, 0.2);
  }
  .homeimg {
    object-fit: cover;
    width: 100%;
  }
  .headerdiv {
    position: absolute;
    top: 50%;
    left: 34%;
    transform: translate(-50%, -50%);
  }
  .homephone {
    font-size: 1.4rem;
    margin: 0;
    padding: 0.5rem;
    background-color: #000000;
  }
  .homeicon {
    height: 1.2rem;
  }
  .logo{
    height:10vw;
  }
  .protecthead {
    font-size: 3.2vw;
    font-weight: 800;
  }
  .protectp {
    font-size: 2vw;
  }
  .protect {
    background-color: #424242;
    padding-top: 2rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 1rem;
    margin-top: 3rem;
    margin-bottom: 4rem;
    color: white;
    display: flex;
  }
  .protecttext {
    width: 60%;
    padding-right: 10rem;
  }
  .protectlist {
    padding-top: 0.5rem;
    width: 40%;
    font-size: 2vw;
    font-weight: 650;
  }

  /* in contact */
  .contactdiv {
    background-color: #424242;
    border-radius: 0.5rem;
    padding: 1rem;
    color: white;
    width: fit-content;
    margin: auto;
    display: flex;
  }
  .connect {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.6rem;
  }
  .connectimg {
    height: 3rem;
    padding: 0.5rem;
  }
  .connectborder {
    border-right: 3px solid white;
    border-bottom: none;
  }
  .connecttext {
    white-space: nowrap;
    font-size: 0.95rem;
    margin: 0;
    font-weight: 650;
  }
  .message {
    display: flex;
    justify-content: center;
    color: white;
    font-size: 2.2vw;
    font-weight: 650;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  /* in contact */
  .contactdiv {
    background-color: #424242;
    border-radius: 0.5rem;
    padding: 1rem;
    color: white;
    width: fit-content;
    margin: auto;
    display: flex;
  }
  .connect {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
  }
  .connectimg {
    height: 4rem;
    padding: 1rem;
  }
  .connectborder {
    border-right: 3px solid white;
    border-bottom: none;
  }
  .connecttext {
    font-size: 1.05rem !important;
    margin: 0;
    font-weight: 650;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  /* in footer */
  .footer {
    background-color: #000000;
    width: 100%;
    bottom: 0;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px,
      rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
      rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
  }
  .footerbody {
    min-height: 15rem;
    display: flex;
    justify-content: space-between;
  }
  .footerbio {
    padding-top: 2rem;
    padding-right: 2rem;
    padding-left: 2rem;
    color: white;
    width: 33.3%;
    display: flex;
    flex-direction: column;
    border-right: 3px solid white;
  }
  .footernavdiv {
    width: 33.3%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-right: 3px solid white;
    border-bottom: none;
    padding-top: 2rem;
    padding-right: 2rem;
    padding-left: 2rem;
    justify-content: flex-start;
  }
  .footernav {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: none;
  }
  .footerinfo {
    width: 33.3%;
    color: white;
    padding-top: 2rem;
    padding-right: 2rem;
    padding-left: 2rem;
    padding-bottom: 1rem;
  }
  .footerimg {
    height: 1.3rem;
  }
  .footercopyright {
    width: 100%;
    background-color: #1c1c1c;
    color: white;
    display: flex;
    justify-content: center;
    align-content: center;
  }
  .footernavtitle {
    color: white;
    margin: 0;
  }
  .footerlink {
    font-size: 1rem;
    margin: 0rem;
    color: white !important;
  }
  .footerlink :hover {
    color: #454545 !important;
  }
  .footerp{
    font-size:1rem;
  }
  .footerhead{
    margin-bottom:1.5rem;
    font-weight:650;
  }

  /* in about */
  .bio {
    margin: auto;
    border-radius: 0.2rem;
    background-color: #ff0000;
    padding: 2rem;
    color: white;
    margin-bottom: 4rem;
    width: 70%;
    margin-top: 2rem;
  }
  .biotext {
    margin: 0;
    text-align: center;
    font-size: 1.3rem;
  }
  .chrispaul {
    margin: 0;
    text-align: center;
    font-size: 1.2rem;
  }
  .fact1 {
    background-color: #424242;
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 1rem;
    margin-bottom: 1.5rem;
    border-radius: 1rem;
    height: 15rem;
    width: 15rem;
    font-size: 3.5rem;
    font-weight: 800;
  }
  .fact2 {
    background-color: #424242;
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 1.2rem;
    margin-bottom: 1.5rem;
    border-radius: 1rem;
    height: 15rem;
    width: 15rem;
    font-size: 1.7rem;
    font-weight: 800;
  }
  .fact3 {
    background-color: #424242;
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 1rem;
    margin-bottom: 1.5rem;
    border-radius: 1rem;
    height: 15rem;
    width: 15rem;
    font-size: 2.5rem;
    font-weight: 800;
  }
  .fact4 {
    background-color: #424242;
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 1rem;
    margin-bottom: 1.5rem;
    border-radius: 1rem;
    height: 15rem;
    width: 15rem;
    font-size: 1.8rem;
    font-weight: 800;
  }
  .facts {
    display: flex;
    color: white;
    justify-content: space-evenly;
  }
  .title {
    font-size: 4vw;
    color: white;
    display: flex;
    justify-content: center;
    margin-top: 3rem;
    font-weight: 900;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2),
      0px -5px 35px rgba(255, 255, 255, 0.3);
  }
  .aboutsection {
    display: flex;
    height: 20rem;
    margin-top: 3rem;
  }
  .personbiodiv {
    color: white;
    background-color: #424242;
    padding: 3rem;
    text-align: center;
    min-width: 36rem;
  }
  .personimg {
    object-fit: cover;
  }
  .abouttitle {
    font-weight: 900;
    margin-bottom: 2rem;
  }
  .atitle {
    font-size: 3.1rem;
    color: white;
    display: flex;
    justify-content: center;
    margin-top: 3rem;
    font-weight: 900;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2),
      0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  /* in services */
  .servicesdiv {
    display: flex;
    background-color: #454545;
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding: 2rem;
  }
  .service {
    width: 33.3%;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1.5rem;
    text-align: center;
  }
  .serviceicon {
    width: 18rem;
    margin-bottom: 2rem;
    border-radius: 0.2rem;
  }
  .serviceicon2 {
    width: 25vw;
    margin-bottom: 2rem;
    border-radius: 0.2rem;
  }
  .servicehead {
    font-size: 2rem;
    font-weight: 800;
  }
  .servicep {
    font-size: 1rem;
  }
  .servicetitle {
    font-size: 3.5vw;
    color: white;
    display: flex;
    justify-content: center;
    margin-top: 3rem;
    font-weight: 900;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2),
      0px -5px 35px rgba(255, 255, 255, 0.3);
  }
  .actionpics {
    display: table;
    table-layout: fixed;
    width: 100%;
    text-align: center;
  }
  .actionpic {
    height: 15rem;
    border-radius: 0.6rem;
    padding: 0.8rem;
  }
  /* .actionpic:hover {
    height: 16rem;
    border-radius: 0.6rem;
    padding: 0.8rem;
  } */
  .actionpic2 {
    height: 15rem;
    border-radius: 0.6rem;
    padding: 0.8rem;
  }
  /* .actionpic2:hover {
    height: 16rem;
    border-radius: 0.6rem;
    padding: 0.8rem;
  } */
  .serviceborder {
    border-right: 3px solid white;
  }
  .infodiv {
    background-color: #ff0000;
    color: white;
    padding: 1rem;
    width: fit-content;
    display: flex;
    justify-content: center;
    margin: auto;
    border-radius: 0.3rem;
    margin-bottom: 2rem;
  }
  .moreinfo {
    margin: 0;
    font-weight: 650;
    font-size: 1.2rem;
  }
  /* .serviceimg1{
    height:2rem;
    margin-right:.5rem;
    margin-bottom:.7rem;
  }
  .serviceimg2{
    height:2rem;
    padding-right:.5rem;
    margin-bottom:.7rem
  }
  .serviceimg3{
    height:3rem;
    padding-right:.5rem;
    padding-bottom:1rem
  } */

  /* in home */
  .homehead {
    font-size: 5rem;
    font-weight: 900;
    text-shadow: 5px 5px 0px rgba(0, 0, 0, 0.2);
  }
  .hometitle {
    font-size: 2.5rem;
    font-weight: 800;
    text-shadow: 5px 5px 0px rgba(0, 0, 0, 0.2);
  }
  .homeimg {
    object-fit: cover;
    width: 100%;
  }
  .headerdiv {
    position: absolute;
    top: 50%;
    left: 34%;
    transform: translate(-50%, -50%);
  }
  .homespacer {
    background-color: #000000;
    color: white;
    display: flex;
    justify-content: center;
  }
  .spaceimg {
    height: 12vw;
  }
  .spaceimg:hover {
    height: 15vw;
    border: 3px solid white;
    border-radius: 0.2rem;
  }
  .homephone {
    font-size: 1.4rem;
    margin: 0;
    padding: 0.5rem;
    background-color: #000000;
  }
  .homeicon {
    height: 1.2rem;
  }
  .logo{
    height:12rem;
  }
  .protecthead {
    font-size: 2.8rem;
    font-weight: 800;
  }
  .protectp {
    font-size: 2rem;
  }
  .protect {
    background-color: #424242;
    padding-top: 3rem;
    padding-left: 3rem;
    padding-right: 3rem;
    padding-bottom: 2rem;
    margin-top: 3rem;
    margin-bottom: 4rem;
    color: white;
    display: flex;
  }
  .protecttext {
    width: 60%;
    padding-right: 10rem;
  }
  .protectlist {
    padding-top: 0.5rem;
    width: 40%;
    font-size: 1.4rem;
    font-weight: 650;
  }

  /* in contact */
  .contactdiv {
    background-color: #424242;
    border-radius: 0.5rem;
    padding: 1rem;
    color: white;
    width: fit-content;
    margin: auto;
    display: flex;
  }
  .connect {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
  }
  .connectimg {
    height: 4rem;
    padding: 1rem;
  }
  .connectborder {
    border-right: 3px solid white;
    border-bottom: none;
  }
  .connecttext {
    font-size: 1.315rem;
    margin: 0;
    font-weight: 650;
  }
  .message {
    display: flex;
    justify-content: center;
    color: white;
    font-size: 1.8rem;
    font-weight: 650;
  }
}
